enum SERVERS {
  US = "us",
  EU = "eu",
}

const SERVER_LOCATION = "SERVER_LOCATION";

const usButton = document.getElementById(SERVERS.US) as HTMLButtonElement;
const euButton = document.getElementById(SERVERS.EU) as HTMLButtonElement;

function buttonClick(e: MouseEvent) {
  const target = e.target as HTMLButtonElement;

  if (usButton && euButton) {
    usButton.disabled = true;
    euButton.disabled = true;
  }

  document.cookie = `${SERVER_LOCATION}=${target.id}; domain=; path=/`;
  window.location.href = `https://my.genomate.health/${target.id}/login`;
}

if (usButton) usButton.onclick = buttonClick;
if (euButton) euButton.onclick = buttonClick;
